import React from 'react';
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'

class CampaignList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loaded: false,
			campaigns: {
				columns: [{
					label: 'Campaign name',
					key: 'name'
				}, {
					label: 'Client',
					key: 'client'
				}, {
					label: 'Duration',
					key: 'duration',
					view: function() {
						return 'Inifinite'
					}
				}, {
					label: 'Status',
					key: 'status'
				}, {
					label: 'Devices',
					key: 'devices',
					view: function(field) {
						return field.length
					}
				}, {
					label: 'Priority',
					key: 'priority'
				}, {
					label: 'Last update',
					key: 'updatedAt',
					view: function(field){
						return moment(field).format('l')
					}
				}, {
					action: 'delete'
				}],
				entities: [],
				filters: [{
					label: 'Search for a campaign',
					onChange: function(){}
				}, {
					label: 'Search for a client',
					onChange: function(){}
				}],
				actions: [],
				selectedItems: [],
				multiSelect: false
			}
		}
	}

	componentDidMount() {
		API.getCampaigns().then(entities => this.setState({ ...this.state, campaigns: {...this.state.campaigns, entities: entities} }))
	}

	render() {
		if(!this.state.campaigns.entities.length)
			return <Loader />
		else
			return <>
				<Head title={'Campaigns'} />
				<Content>
					<Table settings={this.state.campaigns} />
				</Content>
			</>
	}
}

export default CampaignList;
