import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import Header from './header'
import Login from '../account/login'

import PrivateRoute from './privateRoute'

/* Client */
import ClientList from '../clients/list.jsx'
import ClientNew from '../clients/new.jsx'
import ClientEdit from '../clients/edit.jsx'
import ClientView from '../clients/view.jsx'
import ClientCampaignNew from '../clients/campaigns/new.jsx'
import ClientCampaignEdit from '../clients/campaigns/edit.jsx'
import ClientDevicesNew from '../clients/devices/new.jsx'
import ClientDevicesView from '../clients/devices/view.jsx'
import ClientDevicesEdit from '../clients/devices/edit.jsx'

/* Devices */
import DeviceList from '../devices/list.jsx'
import DeviceView from '../devices/view.jsx'
import DeviceEdit from '../devices/edit.jsx'

/* Campaigns */
import CampaignsList from '../campaigns/list.jsx'

/* Playlists */
import PlaylistList from '../playlists/list.jsx'
import PlaylistNew from '../playlists/new.jsx'
import PlaylistView from '../playlists/view.jsx'
import PlaylistEdit from '../playlists/edit.jsx'



function Layout() {
	return (
		<Router>
			<Header />
			<main id="main">
				<ToastContainer position="top-right" />
				<Switch>
					<Route exact path="/login" component={Login} />
					<PrivateRoute exact path="/clients" component={ClientList} />
					<PrivateRoute exact path="/clients/new" component={ClientNew} />
					<PrivateRoute exact path="/clients/:clientId/edit" component={ClientEdit} />

					<PrivateRoute exact path="/clients/:clientId/campaigns" component={ClientView} />
					<PrivateRoute exact path="/clients/:clientId/campaigns/new" component={ClientCampaignNew} />
					<PrivateRoute exact path="/clients/:clientId/campaigns/:campaignId" component={ClientCampaignEdit} />

					<PrivateRoute exact path="/clients/:clientId/devices" component={ClientView} />
					<PrivateRoute exact path="/clients/:clientId/devices/new" component={ClientDevicesNew} />
					<PrivateRoute exact path="/clients/:clientId/devices/:deviceId" component={ClientDevicesView} />
					<PrivateRoute exact path="/clients/:clientId/devices/:deviceId/edit" component={ClientDevicesEdit} />

					<PrivateRoute exact path="/clients/:clientId/playlists" component={ClientView} />
					<PrivateRoute exact path="/clients/:clientId/collections" component={ClientView} />

					<PrivateRoute exact path="/devices" component={DeviceList} />
					<PrivateRoute exact path="/devices/:deviceId" component={DeviceView} />
					<PrivateRoute exact path="/devices/:deviceId/edit" component={DeviceEdit} />

					<PrivateRoute exact path="/campaigns" component={CampaignsList} />
					<PrivateRoute exact path="/campaigns/new" component={CampaignsList} />
					<PrivateRoute exact path="/campaigns/:campaignId" component={CampaignsList} />

					<PrivateRoute exact path="/playlists" component={PlaylistList} />
					<PrivateRoute exact path="/playlists/new" component={PlaylistNew} />
					<PrivateRoute exact path="/playlists/:playlistId" component={PlaylistView} />
					<PrivateRoute exact path="/playlists/:playlistId/edit" component={PlaylistEdit} />

					<Route
						exact
						path="/"
						render={() => {
							return (
								<Redirect to="/clients" />
							)
						}}
					/>
				</Switch>
			</main>
		</Router>
	)
}

export default Layout
