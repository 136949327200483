import React from 'react';
import Table from '../../layout/table.jsx'

class PlaylistList extends React.Component {
	constructor(props) {
		super(props)
		let that = this

		this.state = {
			loaded: false,
			playlists: {
				columns: [{
					label: 'Playlist name',
					key: 'name'
				}, {
					label: 'Tracks',
					key: 'tracks'
				}, {
					label: 'Duration',
					key: 'duration',
					view: function(field) {
						return field + 'mn'
					}
				}, {
					label: 'Vox tag',
					key: 'tag'
				}, {
					label: 'Type',
					key: 'type'
				}, {
					label: 'Updated',
					key: 'updatedAt'
				}, {
					action: 'delete'
				}],
				entities: [],
				filters: [{
					label: 'Search for a playlist',
					onChange: function(){}
				}],
				actions: [{
					classList: 'red delete',
					label: 'Delete',
					onClick: function(){}
				}, {
					classList: 'blue add',
					label: 'New playlist',
					onClick: function(){}
				}],
				selectedItems: [],
				multiSelect: true,
				onRowClick: function(entity) {
					that.props.history.push('/clients/' + props.match.params.id + '/playlists/' + entity.id)
				}
			}
		}
	}

	render() {
		return <>
			<Table settings={this.state.playlists} />
		</>
	}
}

export default PlaylistList;
