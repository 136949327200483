import React from 'react';
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import Form from '../../layout/form.jsx'
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import { toast } from 'react-toastify'

class DeviceEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'View device', path: '/clients/' + props.match.params.clientId + '/devices/' + props.match.params.deviceId },
			fields: {
				sku: { label: 'SKU', type: 'text', required: true },
				type: { label: 'Device type', type: 'select', options: [{ key: 'gel-dispenser-001', label: 'Gel Dispenser Kershan' }], required: true }
			},
			device: null
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
		API.getDevice(this.props.match.params.deviceId).then(device => this.setState({ loading: false, device: device }))
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		API.update('devices', this.props.match.params.deviceId, fields).then(() => {
			toast.success('Device has been updated')
			this.props.history.push('/clients/' + this.props.match.params.clientId + '/devices/' + this.props.match.params.deviceId)
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Edit device'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} />
				<Content>
					<Form fields={this.state.fields} entity={this.state.device} cancel={() => this.props.history.push('/clients/' + this.props.match.params.clientId + '/devices/' + this.props.match.params.deviceId)} save={this.saveEntity} />
				</Content>
			</>
	}
}

export default DeviceEdit;
