import React from 'react';
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import DeviceView from '../../devices/viewDevice.jsx'

class ViewDevice extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Device list', path: '/clients/' + props.match.params.clientId + '/devices' },
			fields: {
				name: { label: 'Name', type: 'text' },
				sku: { label: 'SKU', type: 'text' },
				type: { label: 'Device type', type: 'select', options: [{ key: 'pipo', label: 'Pipo' }] },
				location: { label: 'Lobby', type: 'text' },
				manual: { label: 'Authorize manual mode', type: 'boolean' }
			},
			device: null
		}
	}

	componentDidMount() {
		API.getDevice(this.props.match.params.deviceId).then(device => this.setState({ loading: false, device: device }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Device ' + this.state.device.sku} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} edit={() => this.props.history.push('/clients/' + this.props.match.params.clientId + '/devices/' + this.props.match.params.deviceId + '/edit')} />
				<Content>
					<DeviceView device={this.state.device} />
				</Content>
			</>
	}
}

export default ViewDevice;

