import React from 'react';
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import Loader from '../layout/loader.jsx'
import moment from 'moment'
import { toast } from 'react-toastify'

class ClientList extends React.Component {
	constructor(props) {
		super(props)
		let that = this

		this.state = {
			loading: true,
			clients: {
				columns: [{
					label: 'Name',
					key: 'name'
				}, {
				// 	label: 'Devices',
				// 	key: 'devices'
				// }, {
					label: 'First Name',
					key: 'firstName'
				}, {
					label: 'Last Name',
					key: 'lastName'
				}, {
					label: 'Email',
					key: 'email'
				}, {
					label: 'Phone',
					key: 'phone'
				}, {
					label: 'Created at',
					key: 'createdAt',
					view: function(field){
						return moment(field).format('l')
					}
				}, {
					label: 'Updated at',
					key: 'updatedAt',
					view: function(field){
						return moment(field).format('l')
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete this client?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('clients', id).then(() => {
							toast.success('Client was successfully deleted')
							this.loadEntities()
						}).catch(() => {
							toast.success('An unexpected error has occured')
							this.setState({ loading: false })
						})
					}
				}],
				entities: [],
				filters: [{
					label: 'Search a client',
					onChange: function(){}
				}],
				actions: [{
					classList: 'blue add',
					label: 'New client',
					onClick: function(){
						that.props.history.push('/clients/new')
					}
				}],
				selectedItems: [],
				multiSelect: false,
				onRowClick: function(entity) {
					that.props.history.push('/clients/' + entity.id + '/campaigns')
				}
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getClients().then(entities => this.setState({ ...this.state, loading: false, clients: {...this.state.clients, entities: entities} }))
	}

	viewClient(id) {
		this.props.history.push('/clients/' + id + '/campaigns');
	}

	render() {
		if(this.state.loading)
			return <Loader />
		return <>
			<Head title={'Clients'} />
			<Content>
				<Table settings={this.state.clients} />				
			</Content>
		</>
	}
}

export default ClientList;
