import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class ClientNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: {title: 'Back to client list', path: '/clients'},
			fields: {
				name: { label: 'Name', type: 'text', required: true },
				email: { label: 'Email', type: 'email', required: true },
				firstName: { label: 'First Name', type: 'text', required: true },
				lastName: { label: 'Last Name', type: 'text', required: true },
				phone: { label: 'Phone number', type: 'text', required: true }
			},
			entity: {
				name: null,
				email: null,
				firstName: null,
				lastName: null,
				phone: null
			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		API.create('clients', fields).then(() => {
			toast.success('Client has been created')
			this.props.history.push('/clients')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New client'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form fields={this.state.fields} cancel={() => this.props.history.push('/clients')} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default ClientNew;
