import React from 'react';
import Table from '../../layout/table.jsx'

class CollectionsList extends React.Component {
	constructor(props) {
		super(props)
		let that = this

		this.state = {
			loaded: false,
			collections: {
				columns: [{
					label: 'Collection name',
					key: 'name'
				}, {
					label: 'Playlists',
					key: 'playlists'
				}, {
					label: 'Duration',
					key: 'duration',
					view: function(field) {
						return field + 'mn'
					}
				}, {
					label: 'Last update',
					key: 'updatedAt'
				}, {
					action: 'delete'
				}],
				entities: [{
					id: 1234,
					name: 'Collection 1',
					playlists: 'Client 1',
					duration: 689,
					updatedAt: + new Date()
				}, {
					id: 1234,
					name: 'Collection 1',
					playlists: 'Client 1',
					duration: 689,
					updatedAt: + new Date()
				}, {
					id: 1234,
					name: 'Collection 1',
					playlists: 'Client 1',
					duration: 689,
					updatedAt: + new Date()
				}, {
					id: 1234,
					name: 'Collection 1',
					playlists: 'Client 1',
					duration: 689,
					updatedAt: + new Date()
				}],
				filters: [{
					label: 'Search for a collection',
					onChange: function(){}
				}],
				actions: [{
					classList: 'red delete',
					label: 'Delete',
					onClick: function(){}
				}, {
					classList: 'blue add',
					label: 'New collection',
					onClick: function(){}
				}],
				selectedItems: [],
				multiSelect: true,
				onRowClick: function(entity) {
					that.props.history.push('/clients/' + props.match.params.id + '/collections/' + entity.id)
				}
			}
		}
	}

	render() {
		console.log(this.props)
		return <>
			<Table settings={this.state.collections} />
		</>
	}
}

export default CollectionsList;
