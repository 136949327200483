import React from 'react'
import API from '../../services/api.jsx'
import Helpers from '../../services/helpers.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'

class CampaignList extends React.Component {
	constructor(props) {
		super(props)
		let that = this

		this.state = {
			clients: [],
			loading: false,
			playlist: null,
			breadcrumb: {title: 'Back to playlists', path: '/playlists'},
			assets: {
				columns: [{
					label: 'Playlist name',
					key: 'name'
				}, {
					label: 'Type',
					key: 'file',
					view: function(field) {
						if(!field)
							return '-'
						return field.type
					}
				}, {
					label: 'Size',
					key: 'file',
					view: function(field) {
						if(!field)
							return '-'
						return Helpers.formatBytes(field.size)
					}
				}, {
					label: 'Created',
					key: 'createdAt',
					view: function(field) {
						return moment(field).format('l')
					}
				}, {
					label: 'Updated',
					key: 'updatedAt',
					view: function(field) {
						return moment(field).format('l')
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete?");
						if (r === false) return
						let playlist = JSON.parse(JSON.stringify(this.state.playlist))
						if(playlist.assets) {
							playlist.assets = playlist.assets.map(asset => asset.id)
							playlist.assets.splice(key, 1)
						}
						this.setState({ loading: true })
						API.update('playlists', this.state.playlist.id, playlist).then(playlist => {
							API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, loading: false, selectedFile: null, playlist: playlist, assets: { ...this.state.assets, entities: playlist.assets } }))
						}).catch((err) => {
							console.log(err)
							this.setState({ loading: false, selectedFile: null })
							alert('An unexpected error has occured')
						})
					}
				}],
				entities: [],
				filters: [{
					label: 'Search for a playlist',
					onChange: function(){}
				}],
				actions: [{
					classList: 'red delete',
					label: 'Delete media(s)',
					onClick: function(){}
				}, {
					classList: 'blue save',
					label: 'Add media',
					onClick: function(){
						that.upload.click()
					}
				}],
				selectedItems: [],
				multiSelect: true
			},
			selectedFile: null
		}
	}

	componentDidMount() {
		API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, playlist: playlist, assets: { ...this.state.assets, entities: playlist.assets } }))
	}

	onChangeFile(event) {
		event.stopPropagation();
		event.preventDefault();
		var file = event.target.files[0];
		this.setState({ loading: true })
		API.postAsset(file).then((asset) => {
			let playlist = JSON.parse(JSON.stringify(this.state.playlist))
			if(playlist.assets) {
				playlist.assets = playlist.assets.map(asset => asset.id)
				playlist.assets.push(asset.id)
			} else {
				playlist.assets = [asset.id]
			}
			API.update('playlists', this.state.playlist.id, playlist).then(playlist => {
				API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, loading: false, selectedFile: null, playlist: playlist, assets: { ...this.state.assets, entities: playlist.assets } }))
			}).catch((err) => {
				console.log(err)
				this.setState({ loading: false, selectedFile: null })
				alert('An unexpected error has occured')
			})
		}).catch((err) => {
			console.log(err)
			this.setState({ loading: false, selectedFile: null })
			alert('An unexpected error has occured')
		})
	}

	render() {
		if(!this.state.playlist)
			return <Loader />
		else
			return <>
				<Head title={this.state.playlist.name} breadcrumb={this.state.breadcrumb} edit={() => this.props.history.push('/playlists/' + this.props.match.params.playlistId + '/edit')} />
				<Content>
					{
						 this.state.loading === true ? <Loader /> : <>
							<input
								type="file"
								ref={(ref) => this.upload = ref}
								style={{display: 'none'}}
								onChange={this.onChangeFile.bind(this)}
								value={this.state.selectedFile}
							/>
							<Table settings={this.state.assets} />
						 </>
					}
				</Content>
			</>
	}
}

export default CampaignList;
