import React from 'react';
import Helpers from '../../services/helpers.jsx'

function DeviceView(props) {
	function deviceStatus(lastPing) {
		if(!lastPing)
			return <div className="red">offline</div>

		return (+ new Date(lastPing) > + new Date() - 60 * 60 * 1000) ? <div className="green">online</div> : <div className="red">offline</div>
	}

	function deviceStorage(field) {
		if(!field)
			return '-'

		return (field.externalAvailable / field.externalTotal) < 0.3 ?
			<div className="red">{Helpers.formatBytes(field.externalTotal - field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)}</div>
			:
			<div className="green">{Helpers.formatBytes(field.externalTotal - field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)}</div>
	}

	function gelLevel(field) {
		if(field < 25)
			return <div className="red">&lt; 25%</div>

		if(field < 50)
			return <div className="orange">&gt; 25%</div>

		if(field < 75)
			return <div className="green">&gt; 50%</div>

		return <div className="green">&gt; 75%</div>
	}

	return <>
		<div className="block">
			<div className="device-info">
				<div className="cell">
					<i className="icon-sku"></i>
					<span>SKU</span>
					<div>{ props.device.sku || '-' }</div>
				</div>

				<div className="cell">
					<i className="icon-client"></i>
					<span>Client</span>
					<div>{ props.device.client || '-' }</div>
				</div>

				<div className="cell">
					<i className="icon-device"></i>
					<span>Device Type</span>
					<div>{ props.device.deviceType || '-' }</div>
				</div>

				<div className="cell">
					<i className="icon-status"></i>
					<span>Status</span>
					{ deviceStatus(props.device.lastPing) }
				</div>

				<div className="cell">
					<i className="icon-connectivity"></i>
					<span>Connectivity</span>
					{ props.device.connection || '-' }
				</div>

				<div className="cell">
					<i className="icon-timezone"></i>
					<span>Timezone</span>
					{ props.device.timezone || '-' }
				</div>

				<div className="cell">
					<i className="icon-settings-3"></i>
					<span>Software version</span>
					{ props.device.softwareVersion || '-' }
				</div>

				<div className="cell">
					<i className="icon-storage"></i>
					<span>Storage used</span>
					{ deviceStorage(props.device.storage) }
				</div>

				<div className="cell">
					<i className="icon-name"></i>
					<span>Gel level</span>
					{ gelLevel(props.device.gel) || '-' }
				</div>

				<div className="cell">
					<i className="icon-pin"></i>
					<span>Spray count</span>
					{ props.device.sprayCount || 0 }
				</div>
			</div>
		</div>

		<div className="wrapper">
			<h2>Location</h2>
			{
				props.device.location ? <div className="block">
					<div className="ps"><strong>Shop name:</strong> {props.device.location.name}</div>
					<div className="ps"><strong>Street:</strong> {props.device.location.street}</div>
					<div className="ps"><strong>Street 2:</strong> {props.device.location.street2}</div>
					<div className="ps"><strong>City:</strong> {props.device.location.city}</div>
					<div className="ps"><strong>Zip Code:</strong> {props.device.location.zipCode}</div>
					<div className="ps"><strong>Country:</strong> {props.device.location.country}</div>
				</div> : <div className="block">Location for this device wasn't set</div>

			}
		</div>

		<div className="wrapper">
			<h2>Opening hours</h2>
			{
				props.device.openingHours ? <div className="table-wrapper">
					<table>
						<thead>
							<tr>
								<td>Day</td>
								<td>Morning</td>
								<td>Afternoon</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Monday</td>
								<td>{ props.device.openingHours.mondayMorning.open === true ? props.device.openingHours.mondayMorning.from + '-' + props.device.openingHours.mondayMorning.to : 'closed' }</td>
								<td>{ props.device.openingHours.mondayAfternoon.open === true ? props.device.openingHours.mondayAfternoon.from + '-' + props.device.openingHours.mondayAfternoon.to : 'closed' }</td>
							</tr>
							<tr>
								<td>Tuesday</td>
								<td>{ props.device.openingHours.tuesdayMorning.open === true ? props.device.openingHours.tuesdayMorning.from + '-' + props.device.openingHours.tuesdayMorning.to : 'closed' }</td>
								<td>{ props.device.openingHours.tuesdayAfternoon.open === true ? props.device.openingHours.tuesdayAfternoon.from + '-' + props.device.openingHours.tuesdayAfternoon.to : 'closed' }</td>
							</tr>
							<tr>
								<td>Wednesday</td>
								<td>{ props.device.openingHours.wednesdayMorning.open === true ? props.device.openingHours.wednesdayMorning.from + '-' + props.device.openingHours.wednesdayMorning.to : 'closed' }</td>
								<td>{ props.device.openingHours.wednesdayAfternoon.open === true ? props.device.openingHours.wednesdayAfternoon.from + '-' + props.device.openingHours.wednesdayAfternoon.to : 'closed' }</td>
							</tr>
							<tr>
								<td>Thursday</td>
								<td>{ props.device.openingHours.thursdayMorning.open === true ? props.device.openingHours.thursdayMorning.from + '-' + props.device.openingHours.thursdayMorning.to : 'closed' }</td>
								<td>{ props.device.openingHours.thursdayAfternoon.open === true ? props.device.openingHours.thursdayAfternoon.from + '-' + props.device.openingHours.thursdayAfternoon.to : 'closed' }</td>
							</tr>
							<tr>
								<td>Friday</td>
								<td>{ props.device.openingHours.fridayMorning.open === true ? props.device.openingHours.fridayMorning.from + '-' + props.device.openingHours.fridayMorning.to : 'closed' }</td>
								<td>{ props.device.openingHours.fridayAfternoon.open === true ? props.device.openingHours.fridayAfternoon.from + '-' + props.device.openingHours.fridayAfternoon.to : 'closed' }</td>
							</tr>
							<tr>
								<td>Staturday</td>
								<td>{ props.device.openingHours.saturdayMorning.open === true ? props.device.openingHours.saturdayMorning.from + '-' + props.device.openingHours.saturdayMorning.to : 'closed' }</td>
								<td>{ props.device.openingHours.saturdayAfternoon.open === true ? props.device.openingHours.saturdayAfternoon.from + '-' + props.device.openingHours.saturdayAfternoon.to : 'closed' }</td>
							</tr>
							<tr>
								<td>Sunday</td>
								<td>{ props.device.openingHours.sundayMorning.open === true ? props.device.openingHours.sundayMorning.from + '-' + props.device.openingHours.sundayMorning.to : 'closed' }</td>
								<td>{ props.device.openingHours.sundayAfternoon.open === true ? props.device.openingHours.sundayAfternoon.from + '-' + props.device.openingHours.sundayAfternoon.to : 'closed' }</td>
							</tr>
						</tbody>
					</table>
				</div> : <div className="block">Opening hours for this device wasn't set</div>
			}
		</div>
	</>
}

export default DeviceView;
