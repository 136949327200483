import React from 'react';
import Table from '../layout/table.jsx'
import { DateRangePicker } from 'react-dates'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import Rodal from 'rodal'
import Select from 'react-select'
import TimePicker from 'rc-time-picker'
import { toast } from 'react-toastify'

const localizer = momentLocalizer(moment)

const days = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday'
]

let formats = {
  dayFormat: (date: Date, culture?: string, localizer?: any) =>
    // Change default heading day format from 'Tue 13/11' to 'Tuesday'
    localizer.format(date, 'dddd'),
  eventTimeRangeFormat: ({ start, end }: { start: Date, end: Date }, culture?: string, localizer?: any) => {
    return localizer.format(start, 'HH:mm') + ' - ' + localizer.format(end, 'HH:mm')
  }
}

function secondsToTime(timestamp) {
	let hours = Math.floor(timestamp / 60 / 60);
	let minutes = Math.floor(timestamp / 60) - (hours * 60);
	let seconds = timestamp % 60;
	return {
		hours, minutes, seconds	
	}
}

class CampaignForm extends React.Component {
	constructor(props) {
		super(props)

		// Convert dates
		if(props.entity.startDate)
			props.entity.startDate = moment(props.entity.startDate)

		if(props.entity.endDate)
			props.entity.endDate = moment(props.entity.endDate)


		// Set state
		this.state = {
			entity: props.entity,
			newEvent: {
				activeDays: {}
			},
			selectedOption: null,
			popup: {
				start: moment(),
				end: moment()
			},
			playlistIndex: props.playlists.map(playlist => playlist.id)
		}

		// Prepare schedules
		let schedules = this.prepareSchedules(props.entity.schedules)
		this.state.schedules = schedules
		this.state.calendarEvents = this.buildCalendarEventList(schedules)
		this.state.schedulesIndex = schedules.map(schedule => schedule.id)
	}

	prepareSchedules(schedules) {
		if(!schedules)
			return []

		schedules.forEach(schedule => {
			schedule.playMode = schedule.playMode || 'random'
			schedule.deleted = false
			schedule.playlist = this.props.playlists[this.state.playlistIndex.indexOf(schedule.playlist)]
			schedule.name = schedule.name || ''
		})

		return schedules
	}

	buildCalendarEventList(schedules) {
		let eventList = []
		schedules.forEach((schedule, i) => {
			if(schedule.deleted)
				return

			for (let key in schedule.activeDays) {
				if(schedule.activeDays[key] === true) {
					if(schedule.endTime >= 86400)
						schedule.endTime = 86399

					let start = schedule.startTime + days.indexOf(key) * 86400
					let end = schedule.endTime + days.indexOf(key) * 86400
					let event = {
						...schedule,
						startTime: new Date(2017, 0, 1, secondsToTime(start).hours, secondsToTime(start).minutes, 0),
						endTime: new Date(2017, 0, 1, secondsToTime(end).hours, secondsToTime(end).minutes, 0),
						existing: i
					}
					eventList.push(event)
				}
			}
		})

		return eventList
	}

	newEvent(e) {
		let day = e.start.getDay()
		this.setState({
			showPopup: true,
			newEvent: {
				startTime: moment(e.start),
				endTime: moment(e.end),
				name: '',
				playlist: null,
				playMode: 'random',
				partialSize: 5,
				activeDays: {
					monday: day === 1 ? true : false,
					tuesday: day === 2 ? true : false,
					wednesday: day === 3 ? true : false,
					thursday: day === 4 ? true : false,
					friday: day === 5 ? true : false,
					saturday: day === 6 ? true : false,
					sunday: day === 0 ? true : false
				}
			},
		})
	}

	openEvent(event) {
		let eventCopy = { ...event }
		eventCopy.startTime = moment(eventCopy.startTime)
		eventCopy.endTime = moment(eventCopy.endTime)
		eventCopy.name = event.name

		this.setState({ newEvent: eventCopy, showPopup: true })
	}

	hidePopup() {
		this.setState({
			showPopup: false
		})
	}

	setEvent() {
		// Check content
		if(!this.state.newEvent.playlist)
			return toast.error('Please select a playlist')

		let days = 0
		for(let key in this.state.newEvent.activeDays)
			if(this.state.newEvent.activeDays[key])
				days++
		if(days < 1)
			return toast.error('Select at least one day')

		let startTime = this.state.newEvent.startTime.toDate()
		let endTime = this.state.newEvent.endTime.toDate()

		let event = {
			...this.state.newEvent,
			startTime: startTime.getHours() * 60 * 60 + startTime.getMinutes() * 60,
			endTime: endTime.getHours() * 60 * 60 + endTime.getMinutes() * 60
		}

		let schedules;
		if(this.state.newEvent.existing || this.state.newEvent.existing === 0) {
			schedules = this.state.schedules
			schedules[this.state.newEvent.existing] = event
		} else{
			schedules = this.state.schedules.concat(event)
		}

		this.setState({ 
			schedules: schedules,
			calendarEvents: this.buildCalendarEventList(schedules),
			showPopup: false
		})
	}

	handleStartTimeChange = value => {
		this.setState({ newEvent: { ...this.state.newEvent, startTime: value } });
	}

	handleEndTimeChange = value => {
		this.setState({ newEvent: { ...this.state.newEvent, endTime: value } });
	}

	handlePlaylistChange = selectedOption => {
		this.setState(
			{ newEvent: { ...this.state.newEvent, playlist: selectedOption }},
			() => console.log(`Option selected:`, selectedOption)
		)
	}

	handleDaysChange = day => {
		this.setState({
			newEvent: {
				...this.state.newEvent,
				activeDays: {
					...this.state.newEvent.activeDays,
					[day]: !this.state.newEvent.activeDays[day]
				}
			}
		})
	}

	handlePlaylistTitleChange = e => {
		this.setState({
			newEvent: {
				...this.state.newEvent,
				name: e.target.value
			}
		})
	}

	handlePlayMode = e => {
		this.setState({
			newEvent: {
				...this.state.newEvent,
				playMode: e.target.value
			}
		})
	}

	switchTab = (e, tab) => {
		e.preventDefault()
		this.setState({ newEvent: { ...this.state.newEvent, type: tab }})
	}

	handlePartialSize = e => {
		const re = /^[0-9\b]+$/;

		// if value is not blank, then test the regex
		if (e.target.value === '' || re.test(e.target.value))
			this.setState({
				newEvent: {
					...this.state.newEvent,
					partialSize: e.target.value
				}
			})
	}

	deleteEvent = e => {
		this.setState({
			newEvent: { ...this.state.newEvent, deleted: true }
		}, () => this.setEvent())
	}

	render() {
		return <>
			<form onSubmit={(e) => { e.preventDefault(); this.props.save(this.state.entity, this.state.schedules) }} className="campaign-form">
				<div className="actions clearfix">
					<div className="buttons clearfix">
						<button className="grey" onClick={(e) => { e.preventDefault(); this.props.cancel() }}>Cancel</button>
						<button className="blue">Save campaign</button>
					</div>
				</div>

				<div className="block above">
					<h2>Details</h2>
					<div className="fields clearfix">
						<div className="field">
							<label>Campaign name</label>
							<input type="text" value={this.state.entity.name} onChange={(e) => this.setState({ entity: { ...this.state.entity, name: e.target.value }})} />
						</div>
						<div className="field">
							<label>Infinite date</label>
							<div className="radio">
								<input type="radio" value="1" name="date-range" checked={this.state.entity.infinite === true} onChange={() => this.setState({ entity: { ...this.state.entity, infinite: true }})} />
								<span>Yes</span>
								<input type="radio" value="0" name="date-range" checked={this.state.entity.infinite === false} onChange={() => this.setState({ entity: { ...this.state.entity, infinite: false }})} />
								<span>No</span>
							</div>
						</div>
						{
							this.state.entity.infinite === false && <div className="field">
								<label></label>
								<DateRangePicker
									startDate={this.state.entity.startDate} 
									startDateId="your_unique_start_date_id" 
									endDate={this.state.entity.endDate}
									endDateId="your_unique_end_date_id"
									onDatesChange={({ startDate, endDate }) => this.setState({ entity: { ...this.state.entity, startDate: startDate, endDate: endDate }})}
									focusedInput={this.state.focusedInput}
									onFocusChange={focusedInput => this.setState({ focusedInput })}
									isOutsideRange={() => false}
								/>
							</div>
						}
					</div>
				</div>

				<div className="block scheduler-wrapper">
					<div>
						<Calendar
							selectable
							localizer={localizer}
							events={this.state.calendarEvents}
							startAccessor="startTime"
							endAccessor="endTime"
							style={{ height: 500 }}
							defaultView={Views.WEEK}
							toolbar={false}
							formats={formats}
							views={['week']}
							defaultDate={new Date(2017, 0, 1)}
							onSelectSlot={this.newEvent.bind(this)}
							onSelectEvent={this.openEvent.bind(this)}
						/>
					</div>
				</div>

				<div className="wrapper">
					<h2>Associated devices</h2>
					<Table settings={this.props.devices} />				
				</div>

			</form>

			<Rodal visible={this.state.showPopup} onClose={this.hidePopup.bind(this)} width={790} height={574}>
				<div className="header"></div>
				<div className="columns">
					<div className="left">
						<h2>Choose a playlist</h2>
						<div className="field">
							<Select
								value={this.state.newEvent.playlist}
								onChange={this.handlePlaylistChange}
								options={this.props.playlists}
								getOptionLabel={playlist => playlist.name}
								getOptionValue={playlist => playlist.id}
							/>
						</div>

						<h4>Play mode</h4>
						<div className="field">
							<div className="radio">
								<label>
									<input type="radio" name="play-mode" value="random" checked={this.state.newEvent.playMode === 'random'} onChange={this.handlePlayMode} />
									<span>Random</span>
								</label>
								<label>
									<input type="radio" name="play-mode" value="normal" checked={this.state.newEvent.playMode === 'normal'} onChange={this.handlePlayMode} />
									<span>Normal</span>
								</label>
							</div>
						</div>
					</div>
					<div className="right">
						<h2>Schedule</h2>

						<h4>Time</h4>
						<div className="field">
							<TimePicker showSecond={false} value={this.state.newEvent.startTime} onChange={this.handleStartTimeChange} />
							<TimePicker showSecond={false} value={this.state.newEvent.endTime} onChange={this.handleEndTimeChange} />
						</div>

						<h4>Weekly schedule</h4>
						<div className="field">
							<div className="checkbox"><label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={this.state.newEvent.activeDays.sunday} onChange={() => this.handleDaysChange('sunday')} /><span></span>Sunday</label></div>
							<div className="checkbox"><label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={this.state.newEvent.activeDays.monday} onChange={() => this.handleDaysChange('monday')} /><span></span>Monday</label></div>
							<div className="checkbox"><label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={this.state.newEvent.activeDays.tuesday} onChange={() => this.handleDaysChange('tuesday')} /><span></span>Tuesday</label></div>
							<div className="checkbox"><label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={this.state.newEvent.activeDays.wednesday} onChange={() => this.handleDaysChange('wednesday')} /><span></span>Wednesday</label></div>
							<div className="checkbox"><label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={this.state.newEvent.activeDays.thursday} onChange={() => this.handleDaysChange('thursday')} /><span></span>Thursday</label></div>
							<div className="checkbox"><label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={this.state.newEvent.activeDays.friday} onChange={() => this.handleDaysChange('friday')} /><span></span>Friday</label></div>
							<div className="checkbox"><label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={this.state.newEvent.activeDays.saturday} onChange={() => this.handleDaysChange('saturday')} /><span></span>Saturday</label></div>
						</div>
					</div>
				</div>
				<div className="actions">
					<button className="blue" onClick={this.setEvent.bind(this)}>Save</button>
					<button className="transparent" onClick={this.hidePopup.bind(this)}>Cancel</button>					
					<button className="red" onClick={this.deleteEvent.bind(this)}>Delete</button>					
				</div>
			</Rodal>
		</>
	}
}

export default CampaignForm;
