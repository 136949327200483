import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class ClientNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: {title: 'Back to client view', path: '/playlists/' + props.match.params.playlistId},
			fields: {
				name: { label: 'Name', type: 'text', required: true },
			},
			entity: null
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
		API.getPlaylist(this.props.match.params.playlistId).then(client => this.setState({ loading: false, entity: client }))
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		API.update('playlists', this.props.match.params.playlistId, fields).then(() => {
			toast.success('Playlist has been updated')
			this.props.history.push('/playlists/' + this.props.match.params.playlistId)
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Edit playlist'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form fields={this.state.fields} cancel={() => this.props.history.push('/clients/' + this.props.match.params.playlistId)} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default ClientNew;
