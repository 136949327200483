import React from 'react';
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Table from '../../layout/table.jsx'
import Helpers from '../../../services/helpers.jsx'
import { toast } from 'react-toastify'

class DeviceList extends React.Component {
	constructor(props) {
		super(props)
		let that = this

		this.state = {
			loading: true,
			devices: {
				columns: [
				// {
				// 	label: 'Device name',
				// 	key: 'name'
				// }, 
				{
					label: 'SKU',
					key: 'sku'
				}, {
					label: 'Status',
					key: 'lastPing',
					view: function(field) {
						if(!field)
							return <div className="red">offline</div>

						let timestamp = + new Date(field)
						let now = + new Date()
						return now - timestamp > 60 * 60 * 1000 ? <div className="red">offline</div> : <div className="green">online</div>
					}
				}, {
					label: 'Gel level',
					key: 'gel',
					view: function(field) {
						if(field < 25) {
							return '< 25%'
						}
						if(field < 50)
							return '> 25%'
						if(field < 75)
							return '> 50%'
						return '> 75%'
					}
				}, {
					label: 'Storage left',
					key: 'storage',
					view: function(field){
						if(!field)
							return '-'
						return Helpers.formatBytes(field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)
					}
				// }, {
				// 	label: 'Playing',
				// 	key: 'playlist'
				}, {
					label: 'Timezone',
					key: 'timezone'
				}, {
					label: 'Software version',
					key: 'softwareVersion'
				}, {
					label: 'Download status',
					key: 'downloadStatus',
					view: function(field) {
						if(!field)
							return '-'
						else if(field.status === 'downloaded')
							return <div class="progress green"><i className="icon-done"></i> Downloaded</div>
						else if(field.status === 'downloading')
							return <div class="progress orange"><i className="icon-downloading"></i> 23% (34/128 tracks)</div>
						else if(field.status === 'error')
							return <div class="progress red"><i className="icon-error"></i> Error</div>
						else
							return '-'
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete this device?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('devices', id).then(() => {
							toast.success('Device was successfully deleted')
							this.loadEntities()
						}).catch(() => {
							toast.error('An unexpected error has occured')
							this.setState({ loading: false })
						})
					}
				}],
				entities: [],
				filters: [
				// {
				// 	label: 'Device name',
				// 	onChange: function(){}
				// }, 
				{
					label: 'SKU',
					onChange: function(){}
				}],
				actions: [{
					classList: 'blue add',
					label: 'New device',
					onClick: () => this.props.history.push('/clients/' + this.props.match.params.clientId + '/devices/new')
				}],
				selectedItems: [],
				multiSelect: false,
				onRowClick: function(entity) {
					that.props.history.push('/clients/' + props.match.params.clientId + '/devices/' + entity.id)
				}
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getDevices({ client: this.props.match.params.clientId }).then(entities => this.setState({ ...this.state, loading: false, devices: {...this.state.devices, entities: entities} }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Table settings={this.state.devices} />
			</>
	}
}

export default DeviceList;
